import { useState, useEffect, useCallback } from 'react';
import { MyAccountData } from 'types';
import { account } from 'utils/apis';
import { useToast } from 'utils/hooks';

import { AppContext } from './Context';

export default function AppProvider({ children }): JSX.Element {
  const [user, setUsr] = useState<string | undefined>();
  const [oem, setClient] = useState<string | undefined>();
  const [showFooterModal, setShowFooterModal] = useState<boolean>(false);
  const [pdfStr, setPdfStr] = useState<string | undefined>();
  const [modalType, setModalType] = useState<string>();
  const [showAccount, setShowAccount] = useState<boolean>(false);
  const [myAccountData, setMyAccountData] = useState<MyAccountData>();
  const [fromTable, setFromTable] = useState<boolean | undefined>(false);
  const [noSubSignin, setNoSubSignin] = useState<boolean>(false);
  const { toast } = useToast();

  const setUser: Function = (user: string | undefined) => {
    const client = window.location.pathname.split('/')[1] || undefined;
    setUsr(user);
    setClient(client);
  };

  const openFooterModal: Function = (type: string, pdfStr?: string) => {
    setPdfStr(pdfStr);
    setModalType(type);
    setShowFooterModal(true);
  };

  const closeFooterModal: Function = () => {
    setShowFooterModal(false);
  };

  const openMyAccount: Function = async (
    email: string,
    token: string,
    userData?: any,
    fromTable?: boolean
  ) => {
    if (!email) return;

    if (!userData) {
      const res = await account(email, token);
      if (res?.error) {
        toast('error', 'error', { message: res.error });
        return;
      }
      if (!res.data) return;
      setMyAccountData(res.data);
    } else {
      setMyAccountData(userData);
    }

    setFromTable(fromTable);
    setShowAccount(true);
  };

  const closeMyAccount: Function = () => {
    setShowAccount(false);
  };

  return (
    <AppContext.Provider
      value={{
        user,
        oem,
        setUser,
        closeFooterModal,
        showFooterModal,
        pdfStr,
        openFooterModal,
        modalType,
        openMyAccount,
        closeMyAccount,
        showAccount,
        myAccountData,
        fromTable,
        setNoSubSignin,
        noSubSignin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
