import styled from 'styled-components';
import { useLanguages } from 'utils/hooks';
import ModalHeader from './components/ModalHeader';
import Modal from 'components/Modal';
import PDFViewer from 'features/page/components/PDFViewer';
import { Paragraph } from 'features/AssetMenuModals/styled';

export const BoldHeading = styled.h3`
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
`;

export const SectionParagraph = styled.p`
  color: RGB(0, 0, 0);
  font-family: 'mmcregular', sans-serif;
  font-size: 1em;
  margin-bottom: 0.4em;
  word-break: normal;
  word-wrap: normal;
  max-width: 100%;
`;

const UL = styled.ul`
  margin-bottom: 1em;
`;

const ListItem = styled.li`
  list-style-type: none;
  margin: 0 0 0.3em 2.7em;
`;

function FooterModals({
  close,
  description,
  pdf,
}: {
  close: Function;
  description?: string;
  pdf?: string;
}) {
  const { messages } = useLanguages();
  const slugs = window.location.pathname.split('/');
  const client = slugs[1];

  const renderMmalContent = () => {
    if (pdf) {
      return (
        <PDFViewer
          path={pdf}
          noWatermark
          canvas={'width: 100% !important; height: 70% !important;'}
        />
      );
    }

    switch (description) {
      case 'Contact Us':
        return (
          <>
            <Paragraph>{messages.mmalContactUsModalP1}</Paragraph>
            <Paragraph>{messages.mmalContactUsModalP2}</Paragraph>
            <div
              style={{ display: 'flex', marginBottom: '1em', width: '100%' }}
            >
              <p style={{ width: '10%' }}>{messages.chat}</p>
              <div style={{ width: '90%' }}>
                <p style={{ marginBottom: '1em' }}>
                  {messages.mmalContactUsModalP3}
                </p>
                <p>{messages.mmalContactUsModalP3_1}</p>
              </div>
            </div>
            <div
              style={{ display: 'flex', marginBottom: '1em', width: '100%' }}
            >
              <p style={{ width: '10%' }}>{messages.email}</p>
              <p style={{ width: '90%' }}>{messages.mmalContactUsModalP4}</p>
            </div>
            <div
              style={{ display: 'flex', marginBottom: '1em', width: '100%' }}
            >
              <p style={{ width: '10%' }}> </p>
              <p style={{ width: '90%' }}>
                <a
                  href="#"
                  style={{ color: '#c00000', textDecoration: 'underline' }}
                >
                  mitsubishi.support@right-2-repair.com.au{' '}
                </a>
              </p>
            </div>
            <div
              style={{ display: 'flex', marginBottom: '1em', width: '100%' }}
            >
              <p style={{ width: '10%' }}> </p>
              <p style={{ width: '90%' }}>{messages.mmalContactUsModalP5}</p>
            </div>
          </>
        );

      case 'Privacy Policy':
        return (
          <div style={{ height: '250px' }}>
            <p style={{ fontWeight: 'bold', margin: '1.6em 0 0.4em 0' }}>
              {messages.mmalPrivacyPolicyModal}
            </p>
            <a
              href="https://www.mitsubishi-motors.com.au/privacy-policy"
              target="_blank"
              style={{ color: '#c00000', marginTop: '1em' }}
            >
              https://www.mitsubishi-motors.com.au/privacy-policy
            </a>
          </div>
        );

      case 'Terms And Conditions':
        return (
          <div style={{ maxHeight: '450px' }}>
            <BoldHeading>
              {messages['Terms and Conditions']?.toUpperCase()}
            </BoldHeading>
            <Paragraph style={{ marginBottom: 0 }}>
              {messages.mitsubishiTechInfo}
            </Paragraph>
            <Paragraph>{messages.termsAndConditions}</Paragraph>
            <Paragraph>{messages.mmalTandCModalDate}</Paragraph>
            <SectionParagraph>{messages.mmalTandCModalP1}</SectionParagraph>
            <SectionParagraph>{messages.mmalTandCModalP2}</SectionParagraph>
            <Paragraph>{messages.mmalTandCModalP3}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection1}</BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection1P1}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection1P2}{' '}
              <span>
                <a
                  href="https://www.mitsubishi-motors.com.au/privacy-policy"
                  style={{ color: '#c00000' }}
                  target="_blank"
                >
                  https://www.mitsubishi-motors.com.au/privacy-policy
                </a>
              </span>{' '}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection1P3}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection1P4}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection2}</BoldHeading>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection2A}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection2AP1}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection2AP2}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection2AP3}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection2AP4}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection2B}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection2BP1}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection2BP2}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection2C}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection2CP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection2D}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection2DP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection2E}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection2EP1}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection3}</BoldHeading>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection3A}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection3AP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection3B}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection3BP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection3C}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection3CP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection3D}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection3DP1}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection3DP2}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection3E}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection3EP1}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection3EP2}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection4}</BoldHeading>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection4A}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection4AP1}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection4AP2}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection4B}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection4BP1}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection5}</BoldHeading>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection5A}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection5AP1}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection6}</BoldHeading>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection6A}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection6AP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection6B}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection6BP1}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection7}</BoldHeading>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection7A}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection7AP1}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection7AP2}
              <span>
                {' '}
                <a
                  href="https://product-catalog.boschaftermarket.com.au/mitsubishi/mitsubishi/"
                  style={{ color: '#c00000' }}
                  target="_blank"
                >
                  https://product-catalog.boschaftermarket.com.au/mitsubishi/mitsubishi/
                </a>
              </span>
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection7AP3}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection7B}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection7BP1}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection7BP2}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection7BP3}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection7BP4}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection7BP5}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection7BP6}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection7C}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection7CP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection7D}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection7DP1}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection7DP2}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection7E}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection7EP1}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection7EP2}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection7F}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection7FP1}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection8}</BoldHeading>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection8A}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection8AP1}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection8AP2}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection9B}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection9BP1}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection9BP2}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection9BP3}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection9BP4}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection9BP5}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection9BP6}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection9BP7}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection9C}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection9CP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection9D}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection9DP1}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection9DP2}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection9E}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection9EP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection9F}
            </BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection9FP1}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalSection9FP2}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection9FP3}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalWarning1}</BoldHeading>
            <Paragraph>{messages.mmalTandCModalWarning1P1}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalWarning2}</BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalWarning2P1}
            </SectionParagraph>
            <SectionParagraph>
              {messages.mmalTandCModalWarning2P2}
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalWarning2P3}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection10}</BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection10P1}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection11}</BoldHeading>
            <SectionParagraph>
              {messages.mmalTandCModalSection11P1}
              <span>
                {' '}
                <a
                  href="www.aasra.com.au"
                  style={{ color: '#c00000' }}
                  target="_blank"
                >
                  www.aasra.com.au
                </a>
              </span>
            </SectionParagraph>
            <Paragraph>{messages.mmalTandCModalSection11P2}</Paragraph>
            <BoldHeading>{messages.mmalTandCModalSection12}</BoldHeading>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection12A}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection12AP1}</Paragraph>
            <Paragraph>{messages.mmalTandCModalSection12AP2}</Paragraph>
            <Paragraph>
              {messages.mmalTandCModalSection12AP3}
              <span>
                {' '}
                <a
                  href="https://www.right-2-repair.com.au/mmal"
                  style={{ color: '#c00000' }}
                  target="_blank"
                >
                  https://www.right-2-repair.com.au/mmal;
                </a>
              </span>
            </Paragraph>
            <Paragraph>
              {messages.mmalTandCModalSection12AP9}
              <strong>1300 13 12 11;</strong>
            </Paragraph>
            <Paragraph>
              {messages.mmalTandCModalSection12AP10}{' '}
              <a
                href="#"
                style={{ color: '#c00000', textDecoration: 'underline' }}
              >
                MTIA-support@mmal.com.au;
              </a>
            </Paragraph>
            <Paragraph>{messages.mmalTandCModalSection12AP4}</Paragraph>
            <Paragraph style={{ margin: '0 0 0 2em', fontWeight: 'bold' }}>
              {messages.mmalTandCModalSection12AP5}
            </Paragraph>
            <Paragraph style={{ margin: '0 0 0 2em', fontWeight: 'bold' }}>
              {messages.mmalTandCModalSection12AP6}
            </Paragraph>
            <Paragraph style={{ margin: '0 0 0 2em', fontWeight: 'bold' }}>
              {messages.mmalTandCModalSection12AP7}
            </Paragraph>
            <Paragraph style={{ margin: '0 0 1em 2em', fontWeight: 'bold' }}>
              {messages.mmalTandCModalSection12AP8}
            </Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection12B}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection12BP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection12C}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection12CP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection12D}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection12DP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection12E}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection12EP1}</Paragraph>
            <BoldHeading
              style={{ fontStyle: 'italic', textDecoration: 'underline' }}
            >
              {messages.mmalTandCModalSection12F}
            </BoldHeading>
            <Paragraph>{messages.mmalTandCModalSection12FP1}</Paragraph>
            <br />
          </div>
        );

      case 'Cookie Policy':
        return (
          <div style={{ height: '250px' }}>
            <p style={{ fontWeight: 'bold', margin: '1.6em 0 0.4em 0' }}>
              {messages.mmalCookiePolicyModal}
            </p>
            <a
              href="https://www.mitsubishi-motors.com.au/privacy-policy/cookie-policy.html"
              target="_blank"
              style={{ color: '#c00000' }}
            >
              https://www.mitsubishi-motors.com.au/privacy-policy/cookie-policy.html
            </a>
          </div>
        );

      default:
        break;
    }
  };

  const renderSubaruContent = () => {
    if (pdf) {
      return (
        <PDFViewer
          path={pdf}
          noWatermark
          canvas={'width: 100% !important; height: 70% !important;'}
        />
      );
    }
    switch (description) {
      case 'Privacy Policy':
        window.open('https://www.subaru.com.au/privacy-policy', '_blank');
        close();
        break;

      case 'Links':
        return (
          <div style={{ height: '400px', marginLeft: 13 }}>
            <div className="flex justify-start gap-x-4">
              <Paragraph style={{ fontSize: '1em', fontWeight: 'bold' }}>
                {messages.aasraFullName}
              </Paragraph>
              <a href="https://aasra.com.au/" target="_blank">
                <span>https://aasra.com.au/</span>
              </a>
            </div>
            <div className="flex justify-start gap-x-4">
              <Paragraph style={{ fontSize: '1em', fontWeight: 'bold' }}>
                {messages.subaruAus}
              </Paragraph>
              <a href={messages.subaruWebsite} target="_blank">
                <span>{messages.subaruWebsite}</span>
              </a>
            </div>
            <div className="flex justify-start gap-x-4">
              <Paragraph style={{ fontSize: '1em', fontWeight: 'bold' }}>
                {messages.findSubaruDealer}
              </Paragraph>
              <a href={messages.subaruRetailersLink} target="_blank">
                <span>{messages.subaruRetailersLink}</span>
              </a>
            </div>
          </div>
        );

      default:
        return <p>AWAITING CONTENT</p>;
    }
  };

  //   Australian Automotive Service and Repair Authority (AASRA) https://aasra.com.au/ https://urldefense.com/v3/__https://aasra.com.au/__;!!Cx6w5kGySA!3PVsbN9NeZvQz3_Vt4i-yQECYrw1IgR1FfdF94WvSSfK8ezopg5N3wEz5fkFrreA9aRMFasI03LXGY76oGTQkBsPRfG4EkQDbVn6LFPs$
  // Subaru Australia https://www.subaru.com.au/
  // Find a Subaru Dealer https://www.subaru.com.au/retailers

  const renderHondaContent = () => {
    if (pdf) {
      return (
        <PDFViewer
          path={pdf}
          noWatermark
          canvas={'width: 100% !important; height: 70% !important;'}
        />
      );
    }
    switch (description) {
      case 'Privacy Policy':
        window.open('https://www.honda.com.au/privacy-collection', '_blank');
        close();
        break;

      case 'Terms And Conditions':
        return (
          <PDFViewer
            path={'/content/HAPL TCs revised 15.05.2024.pdf'}
            noWatermark
            canvas={'width: 100% !important; height: 70% !important;'}
          />
        );

        return (
          <>
            <Paragraph style={{ margin: '2em 0' }}>
              {messages.hondaTandCModalP1}
            </Paragraph>
            <BoldHeading>{messages.endUserLicence}</BoldHeading>
            <BoldHeading>
              1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.introduction}
            </BoldHeading>
            <UL>
              <ListItem>
                {messages.hondaTandCModalP2} (<strong>{messages.we}</strong>,{' '}
                <strong>{messages.us}</strong> {messages.or}{' '}
                <strong>{messages.our}</strong>).
              </ListItem>
              <ListItem>{messages.hondaTandCModalP3}</ListItem>
              <ListItem>
                {messages.hondaTandCModalP4} (
                <strong>{messages.account}</strong>){' '}
                {messages.hondaTandCModalP5}
              </ListItem>
            </UL>
            <BoldHeading>
              2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.account}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP6}</ListItem>
              <ListItem>{messages.hondaTandCModalP7}</ListItem>
              <ListItem>{messages.hondaTandCModalP7_2}</ListItem>
              <ListItem>{messages.hondaTandCModalP7_3}</ListItem>
            </UL>
            <BoldHeading>
              3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection3}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP8}</ListItem>
              <ListItem>{messages.hondaTandCModalP9}</ListItem>
              <ListItem>{messages.hondaTandCModalP10}</ListItem>
              <ListItem>{messages.hondaTandCModalP11}</ListItem>
              <ListItem>{messages.hondaTandCModalP12}</ListItem>
            </UL>
            <BoldHeading>
              4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection4}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP13}</ListItem>
              <ListItem>{messages.hondaTandCModalP14}</ListItem>
            </UL>
            <BoldHeading>
              5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection5}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP15}</ListItem>
              <ListItem>{messages.hondaTandCModalP16}</ListItem>
              <ListItem>{messages.hondaTandCModalP17}</ListItem>
              <ListItem>{messages.hondaTandCModalP18}</ListItem>
              <ListItem>{messages.hondaTandCModalP19}</ListItem>
              <ListItem>{messages.hondaTandCModalP20}</ListItem>
              <ListItem>{messages.hondaTandCModalP21}</ListItem>
            </UL>
            <BoldHeading>
              6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection6}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP22}</ListItem>
              <UL style={{ marginLeft: '2.5em' }}>
                <ListItem>{messages.hondaTandCModalP23}</ListItem>
                <ListItem>{messages.hondaTandCModalP24}</ListItem>
                <ListItem>{messages.hondaTandCModalP25}</ListItem>
                <ListItem>{messages.hondaTandCModalP26}</ListItem>
                <ListItem>{messages.hondaTandCModalP27}</ListItem>
                <ListItem>{messages.hondaTandCModalP28}</ListItem>
                <ListItem>{messages.hondaTandCModalP29}</ListItem>
                <ListItem>{messages.hondaTandCModalP30}</ListItem>
                <ListItem>{messages.hondaTandCModalP31}</ListItem>
              </UL>
              <ListItem>{messages.hondaTandCModalP32}</ListItem>
              <ListItem>
                {messages.hondaTandCModalP33}
                <strong>
                  <a
                    href="https://aasra.com.au/?page_id=512"
                    style={{ textDecoration: 'underline' }}
                    target="_blank"
                  >
                    {messages.hondaAasraPage}
                  </a>
                </strong>
                {messages.hondaTandCModalP331}
              </ListItem>
            </UL>
            <BoldHeading>
              7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.payment}
            </BoldHeading>
            <UL>
              <ListItem>
                {messages.hondaTandCModalP34}
                <strong>{messages.accessPackage}</strong>
                {messages.hondaTandCModalP36}
              </ListItem>
              <ListItem>{messages.hondaTandCModalP37}</ListItem>
              <ListItem>{messages.hondaTandCModalP38}</ListItem>
            </UL>
            <BoldHeading>
              8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection8}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP39}</ListItem>
              <ListItem>{messages.hondaTandCModalP40}</ListItem>
              <UL style={{ marginLeft: '2.5em' }}>
                <ListItem>{messages.hondaTandCModalP41}</ListItem>
                <ListItem>{messages.hondaTandCModalP42}</ListItem>
                <ListItem>{messages.hondaTandCModalP43}</ListItem>
              </UL>
              <ListItem>{messages.hondaTandCModalP44}</ListItem>
              <ListItem>{messages.hondaTandCModalP45}</ListItem>
            </UL>
            <BoldHeading>
              9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.confidentiality}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP46}</ListItem>
              <UL style={{ marginLeft: '2.5em' }}>
                <ListItem>{messages.hondaTandCModalP47}</ListItem>
                <ListItem>{messages.hondaTandCModalP48}</ListItem>
                <ListItem>{messages.hondaTandCModalP49}</ListItem>
              </UL>
              <ListItem>{messages.hondaTandCModalP50}</ListItem>
              <UL style={{ marginLeft: '2.5em' }}>
                <ListItem>{messages.hondaTandCModalP51}</ListItem>
                <ListItem>{messages.hondaTandCModalP52}</ListItem>
                <ListItem>{messages.hondaTandCModalP53}</ListItem>
                <ListItem>{messages.hondaTandCModalP54}</ListItem>
              </UL>
            </UL>
            <BoldHeading>
              10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.privacy}
            </BoldHeading>
            <UL>
              <ListItem>
                {messages.hondaTandCModalP55}
                <em>{messages.hondaTandCModalP56}</em>
                {messages.hondaTandCModalP57}{' '}
              </ListItem>
              <ListItem>{messages.hondaTandCModalP58}</ListItem>
            </UL>
            <BoldHeading>
              11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection11}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP59}</ListItem>
              <ListItem>{messages.hondaTandCModalP60}</ListItem>
              <ListItem>{messages.hondaTandCModalP61}</ListItem>
            </UL>
            <BoldHeading>
              12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection12}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP62}</ListItem>
              <ListItem>{messages.hondaTandCModalP63}</ListItem>
              <ListItem>{messages.hondaTandCModalP64}</ListItem>
            </UL>
            <BoldHeading>
              13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.liability}
            </BoldHeading>
            <UL>
              <ListItem>
                {messages.hondaTandCModalP65}
                <strong>{messages.hondaTandCModalP66}</strong>
                {messages.hondaTandCModalP67}
              </ListItem>
              <ListItem>{messages.hondaTandCModalP68}</ListItem>
              <ListItem>{messages.hondaTandCModalP69}</ListItem>
              <ListItem>{messages.hondaTandCModalP70}</ListItem>
            </UL>
            <BoldHeading>
              14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.indemnity}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP71}</ListItem>
              <ListItem>{messages.hondaTandCModalP72}</ListItem>
              <ListItem>{messages.hondaTandCModalP73}</ListItem>
              <ListItem>{messages.hondaTandCModalP74}</ListItem>
            </UL>
            <BoldHeading>
              15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection15}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP75}</ListItem>
              <UL style={{ marginLeft: '2.5em' }}>
                <ListItem>{messages.hondaTandCModalP76}</ListItem>
                <ListItem>{messages.hondaTandCModalP77}</ListItem>
                <ListItem>{messages.hondaTandCModalP78}</ListItem>
              </UL>
              <ListItem>{messages.hondaTandCModalP79}</ListItem>
            </UL>
            <BoldHeading>
              16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection16}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP80}</ListItem>
            </UL>
            <BoldHeading>
              17.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.hondaTandCSection17}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP81}</ListItem>
              <ListItem>{messages.hondaTandCModalP82}</ListItem>
              <ListItem>{messages.hondaTandCModalP83}</ListItem>
            </UL>
            <BoldHeading>
              18.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.general}
            </BoldHeading>
            <UL>
              <ListItem>
                {messages.hondaTandCModalP84}
                <strong>{messages.including}</strong>&nbsp;
                {messages.and}&nbsp;
                <strong>{messages.suchAs}</strong>&nbsp;
                {messages.hondaTandCModalP85}
              </ListItem>
              <ListItem>{messages.hondaTandCModalP86}</ListItem>
              <ListItem>{messages.hondaTandCModalP87}</ListItem>
              <ListItem>{messages.hondaTandCModalP88}</ListItem>
              <ListItem>{messages.hondaTandCModalP89}</ListItem>
              <ListItem>{messages.hondaTandCModalP90}</ListItem>
              <ListItem>{messages.hondaTandCModalP91}</ListItem>
              <ListItem>{messages.hondaTandCModalP92}</ListItem>
              <ListItem>{messages.hondaTandCModalP93}</ListItem>
              <ListItem>{messages.hondaTandCModalP94}</ListItem>
              <ListItem>{messages.hondaTandCModalP95}</ListItem>
            </UL>
            <BoldHeading>
              19.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {messages.definitions}
            </BoldHeading>
            <UL>
              <ListItem>{messages.hondaTandCModalP96}</ListItem>
              <ListItem>
                <strong>{messages.accessPackage}</strong>&nbsp;
                {messages.hondaTandCModalP97}
              </ListItem>
              <ListItem>
                <strong>{messages.account}</strong>&nbsp;
                {messages.hondaTandCModalP98}
              </ListItem>
              <ListItem>
                <strong>{messages.act}</strong>
                {messages.hondaTandCModalP99}
                <em>{messages.hondaTandCModalP100}</em>&nbsp;
                {messages.hondaTandCModalP101}
              </ListItem>
              <ListItem>
                <strong>{messages.australianRepairer}</strong>
                {messages.hondaTandCModalP102}
              </ListItem>
              <ListItem>
                <strong>{messages.eula}</strong>
                {messages.hondaTandCModalP103}
              </ListItem>
              <ListItem>
                <strong>{messages.nonExcludableGuarantee}</strong>
                {messages.hondaTandCModalP104}
              </ListItem>
              <ListItem>
                <strong>{messages.platform}</strong>
                {messages.hondaTandCModalP105}
              </ListItem>
              <ListItem>
                <strong>{messages.safetyInformation}</strong>
                {messages.hondaTandCModalP102}
              </ListItem>
              <ListItem>
                <strong>{messages.schemeInformation}</strong>
                {messages.hondaTandCModalP102}
              </ListItem>
              <ListItem>
                <strong>{messages.schemeRTO}</strong>
                {messages.hondaTandCModalP102}
              </ListItem>
              <ListItem>
                <strong>{messages.schemeVehicles}</strong>
                {messages.hondaTandCModalP102}
              </ListItem>
              <ListItem>
                <strong>{messages.securityInformation}</strong>
                {messages.hondaTandCModalP102}
              </ListItem>
            </UL>
          </>
        );

      case 'Cookie Policy':
      case 'Links':
        return <p>AWAITING CONTENT</p>;

      default:
        return <p>Currently there is no content for this page</p>;
    }
  };

  // pdf and non-pdf modals requires different styling
  return pdf ? (
    <Modal
      style={{ padding: '1em 2em' }}
      close={close}
      closeXPosition="4%"
      closeYPosition="18px"
      overflow="auto"
    >
      {client === 'mmal' && renderMmalContent()}
      {client === 'subaru' && renderSubaruContent()}
      {client === 'honda' && renderHondaContent()}
    </Modal>
  ) : (
    <Modal
      style={{ width: '55vw', padding: '3em 3em' }}
      close={close}
      closeXPosition="-0.5%"
      overflow="auto"
    >
      {client !== 'honda' && (
        <ModalHeader client={client} description={description} />
      )}
      {client === 'mmal' && renderMmalContent()}
      {client === 'subaru' && renderSubaruContent()}
      {client === 'honda' && renderHondaContent()}
    </Modal>
  );
}

export default FooterModals;
