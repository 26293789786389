import { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { toast as longToast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import PrimaryButton from 'components/PrimaryButton';

import Modal from 'components/Modal';
import AsyncButton from 'components/AsyncButton';
import LinkButton from 'components/LinkButton';
import InputGroup from './components/InputGroup';
import TextInput2 from 'components/TextInput2';
import MultiSelect from 'components/SelectMulti';
import { useOEMList, useOEMUserList, useGAPList } from 'features/page/hooks';
import { GAP, GAPData, OEMList, UserData } from 'types';
import Signup from '../signup';
import StripePayment from '../StripePayment';
import {
  useAuth,
  useConfig,
  useLanguages,
  useLoggedIn,
  useLongToast,
  useToast,
} from 'utils/hooks';
import { useSupabase } from 'utils/supabase';
import isIE from 'utils/isIE';
import * as S from './styled';
import { AppContext } from 'components/App/Context';
import UserForm from './components/UserForm';
import GAPForm from './components/GAPForm';
import { supabase as getSupabase } from 'utils/supabase';
import AasraModal from 'features/aasra';

import logo from 'assets/r2r_logo_new.png';
import aasralogo from 'assets/asra-logo.png';
import { selector } from 'recoil';
import { FSLoader } from 'features/page';
import { filter } from 'lodash';
import AddressForm from './components/AddressForm';
import { otpValidate, otpResend, userPost } from 'utils/apis';
import { AutoScrollService } from 'ag-grid-community';
import SelectOemModal from 'features/aasra/components/SelectOemModal';
import { blobToBase64 } from 'utils/functions';

function R2RSignupModal({
  close,
  image,
  // client,
  page,
}: {
  close: Function | null;
  client?: string;
  image?: string;
  page: any;
}) {
  const client = window.location.pathname.split('/')[1];
  const [pageIndex, setPageIndex] = useState(page);
  const [page1option, setPage1Option] = useState(client);
  const [page2option, setPage2Option] = useState('');
  const [page3option, setPage3Option] = useState('');
  const [terms, setTerms] = useState(false);
  const [aasraRoles, setAasraRoles] = useState<any>(undefined);
  const [processing, setProcessing] = useState<boolean>(false);
  const history = useHistory();
  const [error, setError] = useState(0);

  const [data, setData] = useState<{
    type: string;
    oems: any[];
    account: string;
    access: string;
    code?: string;
  }>({
    type: 'individual',
    oems: [client],
    account: page2option,
    access: page3option,
    code: '',
  });
  const [gapData, setGapData] = useState<GAPData>({} as GAPData);
  const [userState, setUserState] = useState<UserData>({
    type: 'individual',
    oems: [client],
    mobilenumber: '+61',
    account: page2option,
    access: page3option,
  } as any);
  const { defaults } = useConfig();
  const { data: gaps } = useGAPList();
  const { data: oems }: { data: OEMList[] | undefined } = useOEMList();
  const [status, setStatus] = useState<'done' | 'verifyPhone' | ''>('');
  const { signup, createGap } = useOEMUserList();
  const { messages } = useLanguages();
  const { toast } = useToast();
  const { longToast } = useLongToast();
  const [selectedGap, setGapid] = useState<number | undefined>();
  const [subGap, setSubGap] = useState<GAP | undefined>();
  const [availableGaps, setavailableGaps] = useState<any[]>([]);
  const [type, setType] = useState<string>('');
  const [userExists, setUserExists] = useState<boolean>(false);
  const sb = getSupabase();
  const { openFooterModal } = useContext(AppContext);
  const { downloadFile } = useSupabase();

  useEffect(() => {
    if (!aasraRoles) return;
    if (aasraRoles?.length) {
      validateUser();
      setPageIndex(4);
    } else {
      setPageIndex(4);
      toast('noAasra', 'error');
    }
    /* eslint-disable-next-line */
  }, [aasraRoles, type]);

  useEffect(() => {
    if (selectedGap) {
      setGapData(availableGaps.find((x) => x.gapid === selectedGap));
    }
  }, [selectedGap, availableGaps]);

  useEffect(() => {
    if (pageIndex >= 3) return;
    if (defaults?.signuponlybusiness) {
      setPage2Option('business_account');
      setData({ ...data, account: 'business_account' });
      setPageIndex(3);
    }
    if (defaults?.signuponlyindividual) {
      setPage2Option('private_account');
      setData({ ...data, account: 'private_account' });
      setPageIndex(3);
    }
    setPageIndex(3);
  }, [defaults, data, pageIndex]);

  const redirectR2RSignUp = useCallback(() => {
    if (
      page2option == 'private_account' &&
      page3option == 'safety_or_security'
    ) {
      // alert('error');
      setError(1);
    } else {
      // alert('we good');
    }
  }, [page2option, page3option]);

  // useEffect(() => {
  //   setData({ ...data, oems: [page1option] });
  // }, []);

  // useEffect(() => {
  //   setData({ ...data, oems: [page1option] });
  // }, [page1option]);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  useEffect(() => {
    console.log(page2option);
  }, [page2option]);

  useEffect(() => {
    console.log(page3option);
    redirectR2RSignUp();
  }, [page3option, redirectR2RSignUp]);

  const handleUserSubmit = async (type: 'individual' | 'business') => {
    console.log('signup ::::', userState, type);

    if (terms == false) {
      toast('error', 'error', { message: 'Must agree to terms.' });
      return;
    }

    if (userExists) {
      setType(type);
      const resp: any = await userPost(
        { useremailid: userState.useremailid } as UserData,
        type,
        ''
      );
      if (resp?.error) {
        toast('error', 'error', { message: resp.message });
        return;
      }
      if (type === 'individual') {
        console.log('Individual signup');
        setPageIndex(11);
        setTimeout(() => setPageIndex(11), 100);
      } else {
        console.log('BUSINESS signup');
        setPageIndex(5);
        setTimeout(() => setPageIndex(5), 100);
      }
      return;
    }

    if (
      !userState.useremailid ||
      !userState.mobilenumber ||
      !userState.password ||
      !userState.firstname ||
      !userState.surname ||
      !userState?.street1 ||
      // !userState?.street2 ||
      !userState?.city ||
      !userState?.state ||
      !userState?.postcode ||
      (defaults?.signuponlyindividual &&
        (!userState?.companyname || !userState?.abn))
    ) {
      toast('error', 'error', { message: 'Missing fields' });
      return;
    }
    if (userState?.password?.length < 6) {
      toast('passwordTooShort', 'error');
      return;
    }
    if (userState?.mobilenumber?.length !== 12) {
      toast('invalidMobile', 'error');
      return;
    }
    // if (userState.type === 'gap' && (!userState.gapName || !userState.gapCompanyName)) {
    //   toast('error', 'error', { message: 'Missing GAP fields' });
    //   return;
    // }
    // if (userState.type === 'gapUser' && !userState?.gaps?.length) {
    //   toast('error', 'error', { message: 'Missing GAP User fields' });
    //   return;
    // }
    // if (userState.type === 'individual' && !userState?.oems?.length) {
    //   toast('error', 'error', { message: 'Missing GAP User fields' });
    //   return;
    // }
    if (userState.mobilenumber?.split('')[0] !== '+') {
      toast('error', 'error', { message: 'Country phone code required.' });
      return;
    }
    // setPageIndex(pageIndex + 1);
    // alert(userState?.email);

    const payload: any = { ...userState };

    payload.useremailid = payload.useremailid.replace(/\s/g, '');

    delete payload.access;
    delete payload.type;
    delete payload.account;
    delete payload.oems;
    delete payload.password;

    setProcessing(true);
    const resp: any = await signup(
      payload as any,
      userState?.password || 'temppassword',
      type,
      type === 'individual' ? 1 : selectedGap || 1,
      aasraRoles?.map((x) => x?.aasraprofiledescriptionshort)?.join(',')
    );
    setProcessing(false);
    console.log('resp ::::', resp, type, !resp?.error);
    if (resp?.message) {
      toast('notice', 'warning', { message: resp.message });
    } else if (resp?.error) {
      toast('error', 'error', { message: resp.message });
    } else if (resp?.data) {
      window.localStorage.setItem('signup_client', client);
      toast('userCreated', 'success');
    } else {
      window.localStorage.setItem('signup_client', client);
      toast('userCreated', 'success');
    }
    if (!resp?.error) {
      console.log('success, onto verification signup');

      setType(type);

      if (type === 'individual' && resp?.data?.subscription?.length) {
        toast('alreadySubscribed', 'success');
        close?.() || history.push(`/${client}`);
      } else {
        console.log('OTP confirm');
        setPageIndex(15);
        setTimeout(() => setPageIndex(15), 100);
      }

      // else if (type === 'individual') {
      //   setPageIndex(11);
      //   setTimeout(() => setPageIndex(11), 100);
      //   setType(type);
      // } else {
      //   console.log('BUSINESS signup');
      //   setPageIndex(5);
      //   setTimeout(() => setPageIndex(5), 100);
      //   setType(type);
      // }
      // toast('smsVerifySent', 'success');
      // setStatus('verifyPhone');
    }
  };

  const verifyCode = async () => {
    if (!data.code || !userState?.useremailid) {
      toast('error', 'error', { message: 'Missing fields' });
      return;
    }
    const resp: any = await otpValidate(userState?.useremailid, data.code);
    if (!resp?.error) {
      // const e = await add(data?.email || '', data?.oems || []);
      toast('otpSuccess', 'success');
      if (type === 'individual') {
        setPageIndex(11);
      } else {
        setPageIndex(5);
      }
      // history.push('/');
    } else {
      toast('error', 'error', { message: resp.error });
    }
  };

  const resendOTP = async () => {
    if (!userState.mobilenumber || !userState?.useremailid) {
      toast('error', 'error', { message: 'Missing fields' });
      return;
    }
    const resp: any = await otpResend(
      userState?.mobilenumber,
      userState?.useremailid
    );
    if (!resp?.error) {
      toast('otpResendSuccess', 'success');
    } else {
      toast('error', 'error', { message: resp.error });
    }
  };

  const submitGap = async () => {
    if (
      !selectedGap &&
      (!gapData?.gapcompanyname ||
        !gapData?.abn ||
        !gapData?.street1 ||
        // !gapData?.street2 ||
        !gapData?.city ||
        !gapData?.state ||
        !gapData?.postcode)
    ) {
      toast('error', 'error', { message: 'Missing fields' });
      return;
    }
    if (gapData?.abn?.length !== 11) {
      toast('error', 'error', { message: 'ABN invalid' });
      return;
    }
    setProcessing(true);
    const resp: any = await createGap(
      gapData,
      selectedGap,
      defaults?.oemid,
      userState?.useremailid
    );
    setProcessing(false);
    console.log('resp ::::', resp);
    if (!resp.error && !resp?.data?.hasSub) {
      console.log('here ::::');
      setGapid(resp?.data?.gapid);
      setPageIndex(12);
    } else if (resp.error) {
      toast('error', 'error', { message: resp.error });
    } else {
      longToast(messages.businessSuccess, 'success');
      longToast(messages.businessSuccess2, 'success');
      longToast(messages.businessSuccess3, 'success');
      close?.() || history.push(`/${client}`);
    }
  };

  const validateUser = async () => {
    const p1: any = await sb
      .from('tb_users')
      .select('useremailid')
      .eq('useremailid', userState?.useremailid);
    setUserExists(p1?.data?.length > 0);
  };

  const validateAbnPostcode = async () => {
    console.log(gapData);
    setProcessing(true);
    const p1 = sb.from('tb_gap').select('*').eq('abn', gapData?.abn);
    const p2 = sb
      .from('tb_gap')
      .select('*')
      .eq('abn', gapData?.abn)
      .eq('postcode', gapData?.postcode);
    const [r1, r2] = await Promise.all([p1, p2]);
    setavailableGaps(
      r1?.data
        ?.filter((x) => x?.gapid !== 1)
        ?.map((x) => ({
          ...x,
          gapdescription: `${x.street1} ${x.city} ${x.state} ${x.postcode}`,
        })) || []
    );
    const ids = r2?.data?.map((x) => x.gapid) || [];
    if (!ids.length) {
      if (gapData?.postcode) {
        setSubGap(undefined);
        setGapid(undefined);
        // if (r1?.data?.length)
        //   setGapData({ postcode: gapData?.postcode } as GAPData);
      }
      setProcessing(false);
      return;
    }
    const r3 = await sb
      .from('tb_gap_subscriptions')
      .select('*')
      .in('gapid', ids);
    console.log('gap sub ::::', ids, r3?.data);
    setSubGap(r3?.data?.[0]);
    setGapid(r3?.data?.[0]?.gapid);
    setProcessing(false);
  };

  const openModal = async (type: string) => {
    let pdfStr;

    if (client === 'subaru') {
      try {
        const data = await downloadFile(defaults?.[type], defaults.oemid);
        pdfStr = await blobToBase64(data?.data);
      } catch (error) {
        return;
      }
    }

    openFooterModal(type, pdfStr);
    close?.() || history.push(`/${client}`);
  };

  if (pageIndex === 10) {
    if (client === '')
      return (
        <SelectOemModal
          close={() => close?.() || history.push('/')}
          goBack={() => {
            setPage3Option('');
            setPageIndex(3);
          }}
        />
      );
    else
      return (
        <AasraModal
          // email={userState?.useremailid}
          back={() => setPageIndex(3)}
          setEmail={(value) =>
            setUserState({ ...userState, useremailid: value?.toLowerCase() })
          }
          setRoles={setAasraRoles}
          noOkta
          close={(status) => {
            if (status === 'success') setPageIndex(4);
            else close?.() || history.push(`/${client}`);
          }}
          isLogin={false}
        />
      );
  }

  if (pageIndex === 11) {
    return (
      <StripePayment
        close={
          close ||
          function () {
            history.push(`/${client}`);
          }
        }
        type="individual"
        email={userState?.useremailid}
        subType={page3option}
        fromSignup
      />
    );
  }

  if (pageIndex === 12 && selectedGap) {
    return (
      <StripePayment
        close={
          close ||
          function () {
            history.push(`/${client}`);
          }
        }
        type="gap"
        gapid={subGap?.gapid || selectedGap}
        email={userState?.useremailid}
        subType={page3option}
        fromSignup
      />
    );
  }

  return (
    <Modal
      close={() => {
        close?.() || history.push(`/${client}`);
      }}
      style={{ padding: '2rem !important' }}
      overflow="auto"
    >
      <Helmet>
        <title>{messages.signup}</title>
      </Helmet>
      {processing && <FSLoader />}
      {pageIndex === 5 && (
        <div style={{ marginTop: '2em', marginBottom: '-2em' }}>
          <h2 style={{ fontSize: '2em', textAlign: 'center' }}>
            GATEWAY ACCESS PROVIDER (GAP) INFORMATION
          </h2>
          <h3 style={{ fontSize: '1em', textAlign: 'center' }}>
            (SUBSCRIPTION HOLDER)
          </h3>
        </div>
      )}
      <S.Container
        $withAddress={
          [4, 5].includes(pageIndex) && !(pageIndex === 4 && userExists)
        }
      >
        {error == 1 ? (
          <S.AlignerContainer style={{ width: '100%' }}>
            <div className="flex flex-col gap-6" style={{ color: 'black' }}>
              Security information is any information relating to a vehicle’s
              mechanical and electrical security and as a data provider, we MTIA
              are required to restrict access to safety and security information
              to those who don’t meet specified access criteria.
            </div>

            <div className="flex flex-col gap-6" style={{ color: 'black' }}>
              MTIA website requires that users register with AASRA to get the
              access and use the safety and security information. To be assessed
              as a fit and proper person to access safety or security
              information, the individual must meet the criteria prescribed by
              the scheme rules.
            </div>

            <div className="flex flex-col gap-6" style={{ color: 'black' }}>
              Safety Information is any information relating to a hydrogen, high
              voltage, hybrid, electronic propulsion and as a data provider, we
              MTIA are required to restrict access to safety and security
              information to those who don’t meet specified access criteria.
            </div>

            <div className="flex flex-col gap-6" style={{ color: 'black' }}>
              MTIA website requires that users register with AASRA to get the
              access and use the safety and security information. To be assessed
              as a fit and proper person to access safety or security
              information, the individual must meet the criteria prescribed by
              the scheme rules.
            </div>

            <div className="flex flex-col gap-6" style={{ color: 'black' }}>
              Please redirect back the signup page to try again.
            </div>
          </S.AlignerContainer>
        ) : (
          <>
            <S.LeftSection style={{ paddingBottom: '2em' }}>
              {/* {client != '' ? (
                isIE() ? (
                  <S.ImageIE src={image} />
                ) : (
                  <S.Image src={image} />
                )
              ) : isIE() ? (
                <S.ImageIE src={logo} />
              ) : (
                <S.Image src={logo} />
              )} */}

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                style={{ width: '100%' }}
              >
                <S.AlignerContainer style={{ width: '100%' }}>
                  {pageIndex == 1 && client == '' ? ( // User Registration Page - Picking OEM
                    <>
                      <div
                        className="flex flex-col gap-6"
                        style={{
                          color: 'black',
                          marginTop: '150px',
                        }}
                      >
                        <div>Choose an OEM</div>

                        <select
                          name="page1-selection"
                          id="page1-selection"
                          onChange={(e) => setPage1Option(e.target.value)}
                          defaultValue={page1option}
                        >
                          <option value="mitsubishi">Mitsubishi</option>
                          <option value="honda">Honda</option>
                          <option value="subaru">Subaru</option>
                        </select>

                        <button
                          className="bg-black text-white p-3 m4"
                          onClick={(e) => {
                            if (page1option == '') {
                              setPage1Option('mitsubishi');
                              setData({ ...data, oems: [page1option] });
                            }
                            setPageIndex(pageIndex + 1);
                            e.preventDefault();
                          }}
                        >
                          Continue
                        </button>
                      </div>
                      <div className="flex flex-col gap-1"></div>
                    </>
                  ) : pageIndex == 2 ? (
                    <>
                      <div
                        className="flex flex-col gap-6"
                        style={{ color: 'black', marginTop: '150px' }}
                      >
                        <div>Private or Business Account?</div>

                        <button
                          className="bg-black text-white p-3 m4"
                          onClick={(e) => {
                            setPage2Option('private_account');
                            setData({ ...data, account: page2option });
                            setPageIndex(pageIndex + 1);
                            e.preventDefault();
                          }}
                        >
                          Private Account
                        </button>

                        <button
                          className="bg-black text-white p-3 m4"
                          onClick={(e) => {
                            setPage2Option('business_account');
                            setData({ ...data, account: page2option });
                            setPageIndex(pageIndex + 1);
                            e.preventDefault();
                          }}
                        >
                          Business Account
                        </button>

                        <button
                          onClick={(e) => {
                            close?.() || history.push(`/${client}`);
                            e.preventDefault();
                          }}
                        >
                          Exit
                        </button>
                      </div>
                    </>
                  ) : pageIndex == 3 ? (
                    <>
                      {page3option !== 'no_aasra' ? (
                        <div
                          className="flex flex-col"
                          style={{
                            color: 'black',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              gap: '1em',
                              margin: '25px 0 20px',
                            }}
                          >
                            <h2
                              style={{
                                fontSize: '2em',
                                textAlign: 'center',
                                marginBottom: '16px',
                              }}
                            >
                              User SignUp
                            </h2>
                            <div>
                              An AASRA account is required to access this site.
                            </div>

                            <button
                              className="bg-black text-white p-3 m4 rounded"
                              onClick={(e) => {
                                setPage3Option('has_aasra');
                                setData({ ...data, access: page3option });
                                setPageIndex(10);
                                e.preventDefault();
                              }}
                              style={isIE() ? { marginTop: '1.4em' } : {}}
                            >
                              I have an AASRA account
                            </button>

                            <button
                              className="bg-black text-white p-3 m4 rounded"
                              onClick={(e) => {
                                setPage3Option('no_aasra');
                                // setData({ ...data, access: page3option });
                                // setPageIndex(pageIndex + 1);
                                e.preventDefault();
                              }}
                              style={isIE() ? { marginTop: '1.2em' } : {}}
                            >
                              I don't have an AASRA account
                            </button>

                            {client !== '' &&
                              !defaults?.signuponlybusiness &&
                              !defaults?.signuponlyindividual && (
                                <button
                                  className="bg-black text-white p-3 m4"
                                  onClick={(e) => {
                                    setPage3Option('no_aasra_signup');
                                    // setData({ ...data, access: page3option });
                                    setPageIndex(4);
                                    e.preventDefault();
                                  }}
                                >
                                  Signup without an AASRA account
                                </button>
                              )}

                            {/* <select
                          name="page3-selection"
                          id="page3-selection"
                          onChange={(e) => setPage3Option(e.target.value)}
                          defaultValue={page3option}
                        >
                          <option value="safety_or_security">
                            Safety or Security
                          </option>
                          <option value="standard">Standard</option>
                        </select> */}

                            {/* <button
                          className="bg-black text-white p-3 m4"
                          onClick={(e) => {
                            redirectR2RSignUp();
                            setPageIndex(pageIndex + 1);
                            e.preventDefault();
                          }}
                        >
                          Continue
                        </button> */}

                            <button
                              onClick={(e) => {
                                close?.() || history.push(`/${client}`);
                                e.preventDefault();
                              }}
                              style={
                                isIE()
                                  ? { marginTop: '1.2em', outline: 0 }
                                  : { outline: 0 }
                              }
                            >
                              Exit
                            </button>
                          </div>
                        </div>
                      ) : (
                        <S.NoAasra>
                          <div style={{ marginTop: '2em' }}>
                            <img
                              style={{
                                display: 'block',
                                margin: 'auto',
                                width: '250px',
                              }}
                              src={aasralogo}
                            />
                          </div>
                          <br />
                          <div
                            style={{
                              margin: '0 auto',
                              padding: '1em 1.8em 1.8em 1.8em',
                            }}
                          >
                            {client === 'mmal' ? (
                              <p style={{ marginLeft: '0.5em' }}>
                                To access the Mitsubishi Technical Information
                                Australia service, you must be associated with
                                an automotive repair business or a registered
                                training organisation. To confirm these details
                                you should first have accessed AASRA (Australian
                                Automotive Service and Repair Authority):
                              </p>
                            ) : (
                              <div
                                style={{
                                  color: '#000',
                                  marginLeft: '0.6em',
                                  textAlign: 'left',
                                }}
                              >
                                <p style={{ marginBottom: '0.4em' }}>
                                  PLEASE NOTE To Participate in AASRA you must:
                                </p>
                                <ul>
                                  <li style={{ marginBottom: '0.2em' }}>
                                    Provide a Mobile Phone number and utilize a
                                    Smart Phone capable of downloading an
                                    application from either Android or Apple APP
                                    Stores.
                                  </li>
                                  <li style={{ marginBottom: '0.2em' }}>
                                    You must provide a unique email address for
                                    your account. Shared accounts like Service@,
                                    Info@, Shop@ etc will not be accepted. You
                                    may use personal accounts such as gmail,
                                    yahoo, icloud etc.
                                  </li>
                                  <li style={{ marginBottom: '0.2em' }}>
                                    You must be associated with an automotive
                                    repair business or a registered training
                                    organisation
                                  </li>
                                </ul>
                              </div>
                            )}
                            <br />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '10px',
                              }}
                            >
                              <PrimaryButton
                                borderRadius="4px"
                                onClick={() =>
                                  window.open('https://aasra.com.au/', '_blank')
                                }
                                margin="0 0 1em 0"
                                width="70%"
                              >
                                Sign up to AASRA here
                              </PrimaryButton>
                              <button
                                onClick={(e) => {
                                  setPage3Option('');
                                  setPageIndex(pageIndex - 1);
                                  e.preventDefault();
                                }}
                                style={{ color: '#000' }}
                              >
                                Back
                              </button>
                            </div>
                          </div>
                        </S.NoAasra>
                      )}
                    </>
                  ) : pageIndex == 4 ? (
                    <>
                      <div
                        className="flex flex-col gap-6"
                        style={{ color: 'black' }}
                      >
                        <S.ContainerSignUp>
                          {/* <h1>{messages.signup}</h1>
                          <br /> */}
                          {['done', 'verifyPhone'].indexOf(status) === -1 && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '3em',
                              }}
                            >
                              <h2 style={{ fontSize: '2em' }}>User Details</h2>
                              <UserForm
                                data={userState}
                                setData={setUserState}
                                validateUser={validateUser}
                                existingUser={userExists}
                                disableEmail={aasraRoles?.length}
                                signuponlyindividual={
                                  defaults?.signuponlyindividual
                                }
                              />
                              <br />
                              {userExists && (
                                <>
                                  <label
                                    style={{
                                      padding: '0 1.6em',
                                      width: '100%',
                                    }}
                                  >
                                    <input
                                      style={{ textAlign: 'left' }}
                                      type="checkbox"
                                      checked={terms}
                                      onChange={() => {
                                        setTerms(!terms);
                                      }}
                                    />{' '}
                                    &nbsp; By clicking the “agree” button, you
                                    are agreeing to the following:-
                                  </label>{' '}
                                  <Link
                                    to="#"
                                    style={{
                                      color: '#c00000',
                                      cursor: 'pointer',
                                      marginBottom: '24px',
                                      marginTop: '24PX',
                                      textDecoration: 'underline',
                                    }}
                                    onClick={(e) => {
                                      close?.();
                                      openModal('Terms And Conditions');
                                      e.preventDefault();
                                    }}
                                  >
                                    Terms And Conditions
                                  </Link>
                                  <div>
                                    <button
                                      className="bg-black text-white p-3 m4 rounded"
                                      onClick={(e) => {
                                        setPageIndex(pageIndex - 1);
                                        e.preventDefault();
                                      }}
                                      style={{ width: '195px' }}
                                    >
                                      Back
                                    </button>{' '}
                                    {!defaults?.signuponlybusiness && (
                                      <button
                                        className="bg-black text-white p-3 m4"
                                        onClick={(e) => {
                                          handleUserSubmit('individual');
                                          e.preventDefault();
                                        }}
                                        style={{ width: '195px' }}
                                      >
                                        {!!defaults?.signuponlyindividual
                                          ? messages.next
                                          : messages.individual}
                                      </button>
                                    )}{' '}
                                    {!defaults?.signuponlyindividual && (
                                      <button
                                        className="bg-black text-white p-3 m4 rounded"
                                        onClick={(e) => {
                                          handleUserSubmit('business');
                                          e.preventDefault();
                                        }}
                                        style={{ width: '195px' }}
                                      >
                                        {!defaults?.signuponlybusiness
                                          ? messages.business
                                          : messages.next}
                                      </button>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          )}

                          {/* {status === 'verifyPhone' && (
                            <>
                              <TextInput2
                                value={data?.code}
                                label={messages.code}
                                example="9999"
                                onChange={(e) => setData({ ...data, code: e })}
                              />

                              <button
                                className="bg-black text-white p-3 m4"
                                onClick={(e) => {
                                  setPageIndex(pageIndex - 1);
                                  e.preventDefault();
                                }}
                              >
                                Back
                              </button>

                              <button
                                className="bg-black text-white p-3 m4"
                                onClick={(e) => {
                                  verifyCode();
                                  e.preventDefault();
                                }}
                              >
                                {messages.verify}
                              </button>
                            </>
                          )} */}
                        </S.ContainerSignUp>
                      </div>
                    </>
                  ) : pageIndex === 15 ? (
                    <div
                      className="flex flex-col gap-6"
                      style={{ color: 'black' }}
                    >
                      <S.ContainerSignUp $marginTop="3em">
                        <div style={{ marginLeft: '4px' }}>
                          <TextInput2
                            value={data?.code}
                            autoFocus
                            label={`One time pin (OTP) sent to ${userState?.mobilenumber}`}
                            example="Enter One time pin (OTP)"
                            password
                            onChange={(e) => setData({ ...data, code: e })}
                          />
                          {/* <button
                            className="bg-black text-white p-3 m4"
                            onClick={(e) => {
                              setPageIndex(4);
                              e.preventDefault();
                            }}
                          >
                            Back
                          </button> */}
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginTop: '20px',
                            }}
                          >
                            <button
                              className="bg-black text-white p-3 m4"
                              style={{
                                borderRadius: '4px',
                                height: '50px',
                                margin: '12px 4px',
                                width: '100%',
                              }}
                              onClick={(e) => {
                                verifyCode();
                                e.preventDefault();
                              }}
                            >
                              {'Verify One Time Pin (OTP)'}
                            </button>
                            <button
                              className="bg-black text-white p-3 m4"
                              style={{
                                borderRadius: '4px',
                                height: '50px',
                                margin: '12px 4px',
                                width: '100%',
                              }}
                              onClick={(e) => {
                                resendOTP();
                                e.preventDefault();
                              }}
                            >
                              {'Resend One Time Pin (OTP)'}
                            </button>
                            <button
                              style={{
                                display: 'block',
                                margin: '20px auto',
                                outline: 0,
                              }}
                              onClick={(e) => {
                                setPage3Option('');
                                setUserExists;
                                setPageIndex(4);
                                e.preventDefault();
                              }}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </S.ContainerSignUp>
                    </div>
                  ) : pageIndex === 5 ? (
                    <>
                      <div
                        className="flex flex-col gap-6"
                        style={{ color: 'black' }}
                      >
                        <S.ContainerSignUp>
                          <div style={{ marginTop: '3em' }}>
                            <h2
                              style={{ fontSize: '2em', textAlign: 'center' }}
                            >
                              Company Details
                            </h2>
                            <GAPForm
                              validateAbnPostcode={validateAbnPostcode}
                              data={gapData}
                              setData={setGapData}
                              availableGaps={availableGaps}
                              gapid={selectedGap}
                              selectGap={(val) => {
                                const id =
                                  parseInt(val) > 0 ? parseInt(val) : undefined;
                                setGapid(id);
                                if (!id) {
                                  setGapData({} as GAPData);
                                  setavailableGaps([]);
                                } else {
                                  const gap = availableGaps?.find(
                                    (x) => x.gapid === id
                                  );
                                  setGapData(gap);
                                }
                              }}
                            />
                            {/* <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <button
                                className="bg-black text-white p-3 rounded"
                                onClick={(e) => {
                                  submitGap();
                                  e.preventDefault();
                                }}
                                style={{ width: '100%', marginTop: '24px' }}
                              >
                                {messages.subscribe}
                              </button>
                            </div> */}
                          </div>
                        </S.ContainerSignUp>
                      </div>
                    </>
                  ) : pageIndex == 6 ? (
                    <div
                      className="flex flex-col gap-6"
                      style={{ color: 'black' }}
                    >
                      <StripePayment
                        close={
                          close ||
                          function () {
                            history.push(`${client}`);
                          }
                        }
                      ></StripePayment>
                    </div>
                  ) : pageIndex == 7 ? (
                    <>Signup Complete</>
                  ) : null}
                </S.AlignerContainer>
              </form>
            </S.LeftSection>
            {[4, 5].includes(pageIndex) && !(pageIndex === 4 && userExists) ? (
              <div
                className="flex flex-col gap-6 ml-2"
                style={{
                  color: 'black',
                  width: '50%',
                  alignItems: 'flex-start',
                }}
              >
                <S.ContainerSignUp>
                  <div style={{ marginTop: '3em' }}>
                    <h2 style={{ fontSize: '2em', textAlign: 'center' }}>
                      Address Details
                    </h2>
                    <AddressForm
                      validateAbnPostcode={validateAbnPostcode}
                      data={pageIndex === 4 ? userState : gapData}
                      setData={pageIndex === 4 ? setUserState : setGapData}
                      required
                      disabled={!!subGap}
                    />
                  </div>
                </S.ContainerSignUp>
                {pageIndex === 5 && (
                  // <div
                  //   style={{
                  //     display: 'flex',
                  //     justifyContent: 'center',
                  //     width: '100%',
                  //   }}
                  // >
                  <>
                    <button
                      className="bg-black text-white p-3 rounded"
                      onClick={(e) => {
                        submitGap();
                        e.preventDefault();
                      }}
                      style={{
                        display: 'block',
                        width: '100%',
                        marginTop: '24px',
                      }}
                    >
                      {messages.subscribe}
                    </button>
                    <button
                      style={
                        isIE()
                          ? {
                              display: 'block',
                              margin: '20px auto 0 auto ',
                              outline: 0,
                            }
                          : { display: 'block', margin: '0 auto', outline: 0 }
                      }
                      onClick={() => setPageIndex(pageIndex - 1)}
                    >
                      Back
                    </button>
                  </>
                  // </div>
                )}
              </div>
            ) : (
              <S.RightSection>
                {client != '' ? (
                  isIE() ? (
                    <S.ImageIE src={image || defaults?.loginmodalimg} />
                  ) : (
                    <S.Image src={image || defaults?.loginmodalimg} />
                  )
                ) : isIE() ? (
                  <S.ImageIE src="https://oerbchdluxfigwgevqiv.supabase.co/storage/v1/object/sign/0/0/R2R(2).png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiIwLzAvUjJSKDIpLnBuZyIsImlhdCI6MTY1NjkxNjUxOSwiZXhwIjoxOTcyMjc2NTE5fQ.pIYKD0yiVfjKud-QQtPsFgdz9V52QZxeTAh2r-vk8aY" />
                ) : (
                  <S.Image src="https://oerbchdluxfigwgevqiv.supabase.co/storage/v1/object/sign/0/0/R2R(2).png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiIwLzAvUjJSKDIpLnBuZyIsImlhdCI6MTY1NjkxNjUxOSwiZXhwIjoxOTcyMjc2NTE5fQ.pIYKD0yiVfjKud-QQtPsFgdz9V52QZxeTAh2r-vk8aY" />
                )}
              </S.RightSection>
            )}
          </>
        )}
      </S.Container>
      {pageIndex === 4 && !userExists && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            width: '560px',
          }}
        >
          {client === 'honda' && (
            <p style={{ marginBottom: '1em' }}>
              {messages.hondaPrivacyMessage}{' '}
              <a
                href="https://www.honda.com.au/privacy-collection"
                style={{ textDecoration: 'underline' }}
                target="_blank"
              >
                www.honda.com.au/privacy-collection
              </a>{' '}
            </p>
          )}
          <label style={{ padding: 0, width: '100%' }}>
            <input
              style={{ textAlign: 'left' }}
              type="checkbox"
              checked={terms}
              onChange={() => {
                setTerms(!terms);
              }}
            />{' '}
            &nbsp; By clicking the “agree” button, you are agreeing to the
            following:-
          </label>{' '}
          <Link
            to="#"
            style={{
              color: '#c00000',
              cursor: 'pointer',
              marginBottom: '1em',
              marginTop: '1em',
              textDecoration: 'underline',
            }}
            onClick={(e) => {
              close?.();
              openFooterModal('Terms And Conditions');
              e.preventDefault();
            }}
          >
            Terms And Conditions
          </Link>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <button
              className="bg-black text-white p-3  rounded"
              onClick={(e) => {
                setPageIndex(pageIndex - 1);
                e.preventDefault();
              }}
              style={{ flexGrow: 1, margin: '1em 1em 1em 0' }}
            >
              Back
            </button>{' '}
            {!defaults?.signuponlybusiness && (
              <button
                className="bg-black text-white p-3 m4"
                onClick={(e) => {
                  handleUserSubmit('individual');
                  e.preventDefault();
                }}
                style={{ flexGrow: 1, margin: '1em 0 1em 1em ' }}
              >
                {defaults?.signuponlyindividual
                  ? messages.next
                  : messages.individual}
              </button>
            )}{' '}
            {!defaults?.signuponlyindividual && (
              <button
                className="bg-black text-white p-3 rounded"
                onClick={(e) => {
                  handleUserSubmit('business');
                  e.preventDefault();
                }}
                style={{ flexGrow: 1, margin: '1em 0 1em 1em ' }}
              >
                {!defaults?.signuponlybusiness
                  ? messages.business
                  : messages.next}
              </button>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

export default R2RSignupModal;
